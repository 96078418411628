import React, { Component } from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";
import Waves from "../components/Waves";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import MediaQuery from "react-responsive";
import logoWhite from "../images/logo-text-white.png";
import appStore from "../images/app-store.png";
import googlePlayStore from "../images/google-play-badge.png";

class Index extends Component {

	render() {
		return (
			<Layout black={true}>
				<SEO
					title={"Jeti | What's Happening Nearby"}
					description={"Jeti is a social media platform that connects you with people who are nearby, have shared interests or topics, and friends and family members. Our mission is to provide a fun, friendly way to engage with your community, near or far."}
				/>
				<Waves/>
				<div className={"container mx-auto h-screen flex justify-center content-center flex-col space-y-6 lg:space-y-10 hero"}>
					<div className={"flex items-center justify-center"}>
						<img
							src={logoWhite}
							alt={"Jeti Logo"}
							className={"w-5/6 lg:w-1/2"}
						/>
					</div>
					<div className={"flex items-center justify-center"}>
						<span className={"text-white text-2xl text-center"}>
							<p>Made by students at</p>
							<p className={"font-bold text-3xl"}>
								Ohio State.
							</p>
						</span>
					</div>
					<div className={"flex flex-row md:flex-row justify-center items-center space-x-4 md:space-x-8"}>
						<MediaQuery minDeviceWidth={768}>
							{(isDesktop) => (
								<React.Fragment>
									<OutboundLink
										href={"https://apps.apple.com/us/app/id1523579231"}
										target={"_blank"}
									>
										<img
											src={appStore}
											alt={"Jeti on the App Store"}
											width={isDesktop ? 175 : 150}
										/>
									</OutboundLink>
									<OutboundLink
										href={"https://play.google.com/store/apps/details?id=app.jeti"}
										target={"_blank"}
									>
										<img
											src={googlePlayStore}
											alt={"Jeti on the Google Play Store"}
											width={isDesktop ? 195 : 165}
										/>
									</OutboundLink>
								</React.Fragment>
							)}
						</MediaQuery>
					</div>
				</div>
			</Layout>
		);
	}

}

export default Index;
