import React, { Component } from "react";

const Wave = require("../../assets/wave.svg");

class Waves extends Component {

	render() {
		return (
			<div className={"wave-container"}>
				<Wave/>
			</div>
		);
	}

}

export default Waves;
